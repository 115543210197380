import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import axios from 'axios';
import Button from './Button';
import { validateCep } from '../utils/validations/validations';
import { v4 as uuidv4 } from 'uuid';

const CepInput = (props) => {
    const [inputText, setInputText] = useState('');
    const [isPlacheholder, setIsPlacheholder] = useState(true);
    const [loading, setLoading] = useState(false);

    const onChangeInput = (event) => {
        setInputText(event.target.value);
    }

    const showPlaceholder = () => {
        if (isPlacheholder === false) {
            setIsPlacheholder(true);
        } else {
            setIsPlacheholder(false);
        }
    }


    const searchCep = () => {
        if (props.value && validateCep(props.value)) {
            let cepSearch = validateCep(props.value);

            var getCep = async (cep) => {
                setLoading(true);
                var fetchData = {
                    method: "GET",
                    url: `https://api.boxdelivery.com.br/address-service/v1/zipcodes/${cep}`,
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };
                try {
                    const response = await axios(fetchData);
                    props.successNotify('CEP válido! Alguns campos serão preenchidos automaticamente.');
                    return response.data.data;
                } catch (error) {
                    setLoading(false);
                    props.notify('CEP inválido!');
                    return error.response;
                }
            }
            getCep(cepSearch).then(cep => {
                props.setAddress(cep.address);
                props.setAddressState(cep.state);
                props.setAddressNeighborhood(cep.neighborhood);
                props.setAddressCity(cep.city);
                setLoading(false);
            });
        } else {
            props.notify('CEP inválido! Tente novamente.');
        }
    }
    return (
        <div className='cep-container'>
            <div className={`input no-mb`} style={{ width: props.width ? props.width : "100%" }} id={`cep-container`}>
                <InputMask
                    mask="99999-999"
                    maskPlaceholder={null}
                    value={props.value ? props.value : inputText}
                    onChange={props.onChange ? props.onChange : onChangeInput}
                    onFocus={showPlaceholder}
                    onBlur={showPlaceholder}
                    disabled={props.disabled ? true : false}
                    className={`input-inner text${props.value && props.value !== "" ? ' filled' : inputText !== "" ? ' filled' : ''}`}
                    placeholder={isPlacheholder ? "CEP" : ""}
                    type={props.type ? props.type : "text"}
                    name={props.id ? props.id : "cep-input"}
                    id={props.id ? props.id : "cep-input"}
                    autoComplete="off"
                    required
                />
                <label htmlFor={props.id ? props.id : "cep-input"} className="input-label"><span>CEP</span></label>
            </div>
            <Button
                onClick={() => searchCep()}
                text='Procurar'
                width='180px'
                color='yellow'
            />
            {loading &&
                <div className='fallback-container'>
                    <div className='loading-container'>
                        <div className='loading-icon'></div>
                    </div>
                </div>
            }
        </div>
    )
}
export default CepInput;