import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAmbient } from '../providers/AmbientProvider';
import CustomSelect from "./CustomSelect";

const gmtOptions = [
    '(GMT -5:00) - Acre (ACT)',
    '(GMT -4:00) - Amazonia (AMT)',
    '(GMT -3:00) - Brasilia (BRT)',
    '(GMT -2:00) - Fernando de Noronha (FNT)'
]

const Sidebar = (props) => {
    const [open, setOpen] = useState(false);

    const container = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { setHub, hub, setGmt } = useAmbient();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        let gmt = localStorage.getItem('GMT');
        if (gmt === '(GMT -5:00) - Acre (ACT)') {
            setGmt('America/Rio_Branco');
            document.getElementById('gmt-select').value = gmt;
        } else if (gmt === '(GMT -4:00) - Amazonia (AMT)') {
            setGmt('America/Manaus');
            document.getElementById('gmt-select').value = gmt;
        } else if (gmt === '(GMT -3:00) - Brasilia (BRT)') {
            setGmt('America/Sao_Paulo');
            document.getElementById('gmt-select').value = gmt;
        } else if (gmt === '(GMT -2:00) - Fernando de Noronha (FNT)') {
            setGmt('America/Noronha');
            document.getElementById('gmt-select').value = gmt;
        } else {
            setGmt('America/Sao_Paulo');
            document.getElementById('gmt-select').value = '(GMT -3:00) - Brasilia (BRT)';
            localStorage.setItem('GMT', '(GMT -3:00) - Brasilia (BRT)');
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    // useEffect(() => {
    //     if (hub) {
    //         let parsedHub = JSON.parse(hub);
    //         parsedHub.hub && setHub(parsedHub.hub);
    //         parsedHub.seller && props.setSeller(parsedHub.seller);
    //         parsedHub.profile && props.setProfile(parsedHub.profile);
    //     }
    // },[hub, setHub])
    const handleButtonClick = () => {
        setOpen(!open)
    };
    const handleClickOutside = (event) => {
        if (
            container.current &&
            !container.current.contains(event.target)
        ) {
            setOpen(false)
        }
    };
    return (
        <ul className="sidebar-container" id="sidebar-container">
            <div className="hub-filter" onClick={handleButtonClick}>
                {props.isActive && (props.accountType === "HUB" || props.accountType === "EXTERNAL") &&
                    <div className="hub-selected">{hub && hub.name && hub.name}{props.seller && props.seller.name && ` - ${props.seller.name}`}</div>
                }
                {props.isActive && props.accountType === "SELLER" &&
                    <div className="hub-selected">{props.seller && props.seller.name && props.seller.name}{hub && hub.name && ` - ${hub.name}`}</div>
                }
                {props.userData.length > 1 &&
                    <div className="arrow-icon"></div>
                }
            </div>
            {props.userData.length > 1 && open &&
                <div className="hub-dropdown">
                    <ul>
                        {props.accountType === "SELLER" && props.userData && props.userData.map(option => {
                            return (
                                <li key={option.hub.name} onClick={() => {
                                    setHub(option.hub);
                                    localStorage.setItem('backoffice-hub', JSON.stringify(option.hub));
                                    var encodedHub = hub && hub.uuid && props.formatIdToBase64(hub.uuid, 'hub');
                                    var accessToken = localStorage.getItem('access-token');
                                    encodedHub && props.getSellers(accessToken, hub, encodedHub);
                                    encodedHub && props.getSellersNoChild(accessToken, hub, encodedHub);
                                    setOpen(false);
                                }}>{option.seller && option.seller.name ? option.seller.name : ""}{option.hub && option.hub.name ? ` - ${option.hub.name}` : ""}</li>
                            )
                        })}
                        {(props.accountType === "HUB" || props.accountType === "EXTERNAL") && props.userData && props.userData.map(option => {
                            return (
                                <li key={option && option.hub && option.hub.name && option.hub.name} onClick={() => {
                                    setHub(option.hub);
                                    localStorage.setItem('backoffice-hub', JSON.stringify(option.hub));
                                    if ((option.hub.name === 'Parque D Pedro Shopping' || option.hub.name === 'Parque Dom Pedro Shopping' || option.hub.name === 'Shopping Leblon' || option.hub.name === 'Leblon Shopping') && location.pathname === '/transfers') {
                                        navigate('/orders');
                                    }
                                    var encodedHub = hub && hub.uuid && props.formatIdToBase64(hub.uuid, 'hub');
                                    var accessToken = localStorage.getItem('access-token');
                                    encodedHub && props.getSellers(accessToken, hub, encodedHub);
                                    encodedHub && props.getSellersNoChild(accessToken, hub, encodedHub);
                                    setOpen(false);
                                }}>{option.hub && option.hub.name ? option.hub.name : ""}</li>
                            )
                        })}
                    </ul>
                </div>
            }
            <li>
                <NavLink to="/orders" className="sidebar-link" activeclassname="sidebar-link active">
                    <div className="sidebar-image orders"></div>
                    {props.isActive ? <p className="sidebar-title">Pedidos</p> : null}
                </NavLink>
            </li>
            {(props.accountType === "HUB" || props.accountType === "EXTERNAL") && hub && hub.uuid !== 'a32ac0e2-e259-43b3-b709-5eb1c9740d5a' && hub.name !== 'Parque D Pedro Shopping' && hub.name !== 'Parque Dom Pedro Shopping' &&
                <li>
                    <NavLink to="/transfers" className="sidebar-link" activeclassname="sidebar-link active">
                        <div className="sidebar-image transfers"></div>
                        {props.isActive ? <p className="sidebar-title">Transferências</p> : null}
                    </NavLink>
                </li>
            }
            {
                props.accountType === "HUB" && (props.profile === "MASTER" || props.profile === "ADMIN" || props.profile === "SUPERVISOR") &&
                <li>
                    <NavLink to="/users" className="sidebar-link" activeclassname="sidebar-link active">
                        <div className="sidebar-image users"></div>
                        {props.isActive ? <p className="sidebar-title">Usuários</p> : null}
                    </NavLink>
                </li>
            }
            {
                props.accountType === "HUB" && props.profile !== "OPERATOR" && props.profile !== "CALL_CENTER" && props.profile !== "SUPPORT" &&
                <li>
                    <NavLink to="/sellers" className="sidebar-link" activeclassname="sidebar-link active">
                        <div className="sidebar-image sellers"></div>
                        {props.isActive ? <p className="sidebar-title">Lojistas</p> : null}
                    </NavLink>
                </li>
            }
            {
                props.isActive &&
                <CustomSelect
                    placeholder="GMT-3"
                    className={props.profile === "SUPPORT" ? "gmt-select with-margin" : "gmt-select"}
                    options={gmtOptions}
                    id="gmt-select"
                    width="calc(100% - 48px)"
                    onChange={(event) => {
                        localStorage.setItem('GMT', event.target.value);
                        if (event.target.value === '(GMT -5:00) - Acre (ACT)') {
                            setGmt('America/Rio_Branco');
                        } else if (event.target.value === '(GMT -4:00) - Amazonia (AMT)') {
                            setGmt('America/Manaus');
                        } else if (event.target.value === '(GMT -3:00) - Brasilia (BRT)') {
                            setGmt('America/Sao_Paulo');
                        } else if (event.target.value === '(GMT -2:00) - Fernando de Noronha (FNT)') {
                            setGmt('America/Noronha');
                        } else {
                            setGmt('America/Sao_Paulo');
                        }
                    }}
                />
            }
            {props.profile !== "SUPPORT" &&
                <a
                    className={props.isActive && !window.location.href.includes('develop') ? 'support-button' :
                        window.location.href.includes('develop') && props.isActive ? 'support-button mb-dev' :
                            window.location.href.includes('develop') && !props.isActive ? 'support-button mb-dev inactive' :
                                'support-button inactive'
                    }
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://forms.gle/8TNBFfBgfv2vwLpe9'
                >
                    <div className="support-icon" />
                    {props.isActive ? <p>SUPORTE</p> : null}

                </a>
            }
        </ul >
    )
}

export default Sidebar;