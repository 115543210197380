import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import MobileSidebar from './components/MobileSidebar';
import Button from './components/Button';
import $ from 'jquery';
import CreateGoodsOrder from './pages/CreateGoodsOrder';
import SSOLogin from './pages/SSO';
import SignupForm from './pages/SignupForm';
import RecoverPassword from './pages/RecoverPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import qs from 'qs';
import { Buffer } from 'buffer';
import { getBase64 } from './utils/validations/formats';
import { useAmbient } from './providers/AmbientProvider';
import { v4 as uuidv4 } from 'uuid';

const Login = React.lazy(() => import('./pages/Login'));
const Orders = React.lazy(() => import('./pages/Orders'));
const Users = React.lazy(() => import('./pages/Users'));
const CreateUser = React.lazy(() => import('./pages/CreateUser'));
const EditUser = React.lazy(() => import('./pages/EditUser'));
const EditProfile = React.lazy(() => import('./pages/EditProfile'));
const Transfers = React.lazy(() => import('./pages/Transfers'));
const Sellers = React.lazy(() => import('./pages/Sellers'));
const CreateSeller = React.lazy(() => import('./pages/CreateSeller'));
const EditSeller = React.lazy(() => import('./pages/EditSeller'));
const EditSellerProfile = React.lazy(() => import('./pages/EditSellerProfile'));
const ImportOrders = React.lazy(() => import('./pages/ImportOrders'));


toast.configure();
const notify = (message) => toast.error(message);
const successNotify = (message) => toast.success(message);
const CustomToast = (messages) => (
  <div style={{ width: "100%" }}>
    {messages['CEP'] && (
      <div className='address-error-container'>
        <p>CEP</p>
        {messages['CEP'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {messages['Rua'] && (
      <div className='address-error-container'>
        <p>Rua</p>
        {messages['Rua'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {messages['Bairro'] && (
      <div className='address-error-container'>
        <p>Bairro</p>
        {messages['Bairro'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {messages['Cidade'] && (
      <div className='address-error-container'>
        <p>Cidade</p>
        {messages['Cidade'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {messages['Estado'] && (
      <div className='address-error-container'>
        <p>Estado</p>
        {messages['Estado'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {messages['Número'] && (
      <div className='address-error-container'>
        <p>Número</p>
        {messages['Número'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {messages['Complemento'] && (
      <div className='address-error-container'>
        <p>Complemento</p>
        {messages['Complemento'].map(message => {
          return (
            <p>• {message}</p>
          )
        })}
      </div>
    )}
    {/* <a
      className='toast-support-button'
      target='_blank'
      rel='noopener noreferrer'
      href='#'
    >
      <div className="support-icon" />
      <p>SUPORTE</p>
    </a> */}
  </div>
);
const customNotify = (messages) => toast.error(CustomToast(messages));

function App(props) {
  const [pageSize, setPageSize] = useState('');
  const [ssoAuthenticated, setSsoAuthenticated] = useState(false);
  const [isSidebarActive, setIsSidebarActive] = useState(true);
  const [profile, setProfile] = useState('');
  const [seller, setSeller] = useState('');
  const [sellersList, setSellersList] = useState(null);
  const [sellersListNoChild, setSellersListNoChild] = useState(null);
  const [element, setelement] = useState('');
  const [userData, setUserData] = useState(null);
  const [accountName, setAccountName] = useState('');
  const [accountType, setAccountType] = useState('');
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [customLogo, setCustomLogo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refreshTokenModal, setRefreshTokenModal] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePictureLoading, setProfilePictureLoading] = useState(true);

  const { hub, setHub } = useAmbient();

  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    console.log("Variáveis de Ambiente: ", process.env)
    var accessToken = localStorage.getItem('access-token');
    var idToken = localStorage.getItem('id-token');
    if (accessToken) {
      getPermissions(accessToken).then(async (data) => {
        if (data.data && data.data.length !== 0) {
          var accessDecoded = parseJwt(accessToken);
          console.log(accessDecoded)
          let hubLogoUuid = null;
          if (accessDecoded.hubs && accessDecoded.hubs.permissions && accessDecoded.hubs.permissions[0] && accessDecoded.hubs.permissions[0].length > 0) {
            hubLogoUuid = Object.keys(accessDecoded.hubs.permissions[0][0])[0];
          }
          if (hub && hub.uuid && (
            hub.uuid === 'eb0b77b9-6217-4949-8da1-1dc374aaf6b2' ||
            hub.uuid === '432037b3-398f-4ac6-be39-e32d3c613d13' ||
            hub.uuid === '3b6e828c-3bdf-4d78-b7bd-78561205cc28' ||
            hub.uuid === 'dce3a9b8-8e44-43bf-82e4-04d7f364c842' ||
            hub.uuid === '6ed15804-ac10-4448-a0de-aa83151c2aec' ||
            hub.uuid === 'a9fd5a44-d296-48e2-953d-b7d48d69a064' ||
            hub.uuid === '8b5075ea-50ea-4419-9b16-fb5f17a495cb' ||
            hub.uuid === '8c3414f7-4164-436e-acab-835868f0e380' ||
            hub.uuid === '812c74dd-ed7f-4975-a98d-b23aa4a01118' ||
            hub.uuid === '9087f02d-8a8d-4215-aa05-834e1a0311d4' ||
            hub.uuid === 'a32ac0e2-e259-43b3-b709-5eb1c9740d5a'
          )) {
            setCustomLogo(`${process.env.REACT_APP_CUSTOM_GCP}${hub.uuid}.png`);
          } else if (hubLogoUuid && (
            hubLogoUuid === 'eb0b77b9-6217-4949-8da1-1dc374aaf6b2' ||
            hubLogoUuid === '432037b3-398f-4ac6-be39-e32d3c613d13' ||
            hubLogoUuid === '3b6e828c-3bdf-4d78-b7bd-78561205cc28' ||
            hubLogoUuid === 'dce3a9b8-8e44-43bf-82e4-04d7f364c842' ||
            hubLogoUuid === '6ed15804-ac10-4448-a0de-aa83151c2aec' ||
            hubLogoUuid === 'a9fd5a44-d296-48e2-953d-b7d48d69a064' ||
            hubLogoUuid === '8b5075ea-50ea-4419-9b16-fb5f17a495cb' ||
            hubLogoUuid === '8c3414f7-4164-436e-acab-835868f0e380' ||
            hubLogoUuid === '812c74dd-ed7f-4975-a98d-b23aa4a01118' ||
            hubLogoUuid === '9087f02d-8a8d-4215-aa05-834e1a0311d4' ||
            hubLogoUuid === 'a32ac0e2-e259-43b3-b709-5eb1c9740d5a'
          )) {
            setCustomLogo(`${process.env.REACT_APP_CUSTOM_GCP}${hubLogoUuid}.png`);
          } else {
            setCustomLogo(`${process.env.REACT_APP_DEFAULT_GCP}`);
          }
          var encodedHub =
            data.data[0] &&
            data.data[0].hub &&
            data.data[0].hub.uuid &&
            formatIdToBase64(data.data[0].hub.uuid, 'hub');
          accessDecoded.given_name && setAccountName(accessDecoded.given_name);
          accessDecoded.preferred_username && setAccountName(accessDecoded.preferred_username);
          setAccessToken(accessDecoded);
          if (data.data[0] && data.data[0].type && data.data[0].type === 'HUB') {
            getSellers(accessToken, data.data[0].hub, encodedHub);
            getSellersNoChild(accessToken, data.data[0].hub, encodedHub);
          }
          await getUserImage(accessToken).then(async data => {
            let base64 = await getBase64(data.data);
            setProfilePictureLoading(false);
            setProfilePicture(base64);
          });
        } else {
          notify(
            'Usuário sem permissões! Entre em contato com o Administrador.'
          );
          navigate('/');
        }
      });
    }
    if (idToken) {
      var idDecoded = parseJwt(idToken);
      setIdToken(idDecoded);
    }
    setInterval(() => {
      var timeOut = localStorage.getItem('conection-time-out');
      var now = new Date().getTime();
      if (timeOut && now && timeOut < now && !refreshTokenModal) {
        $('#refresh-token-modal').toggle();
        setRefreshTokenModal(true);
      }
    }, 60000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (hub && hub.uuid && (
      hub.uuid === 'eb0b77b9-6217-4949-8da1-1dc374aaf6b2' ||
      hub.uuid === '432037b3-398f-4ac6-be39-e32d3c613d13' ||
      hub.uuid === '3b6e828c-3bdf-4d78-b7bd-78561205cc28' ||
      hub.uuid === 'dce3a9b8-8e44-43bf-82e4-04d7f364c842' ||
      hub.uuid === '6ed15804-ac10-4448-a0de-aa83151c2aec' ||
      hub.uuid === 'a9fd5a44-d296-48e2-953d-b7d48d69a064' ||
      hub.uuid === '8b5075ea-50ea-4419-9b16-fb5f17a495cb' ||
      hub.uuid === '8c3414f7-4164-436e-acab-835868f0e380' ||
      hub.uuid === '812c74dd-ed7f-4975-a98d-b23aa4a01118' ||
      hub.uuid === '9087f02d-8a8d-4215-aa05-834e1a0311d4' ||
      hub.uuid === 'a32ac0e2-e259-43b3-b709-5eb1c9740d5a'
    )) {
      setCustomLogo(`${process.env.REACT_APP_CUSTOM_GCP}${hub.uuid}.png`);
    } else {
      setCustomLogo(`${process.env.REACT_APP_DEFAULT_GCP}`);
    }
  }, [hub]);
  useEffect(() => {
    setTimeout(() => {
      if (refreshTokenModal === true) {
        localStorage.removeItem("access-token");
        localStorage.removeItem("id-token");
        localStorage.removeItem("conection-time-out");
        localStorage.removeItem("profile-picture");
        localStorage.removeItem("refresh-token");
        $(`#refresh-token-modal`).toggle();
        setRefreshTokenModal(false);
        window.location.href = "/";
      }
    }, 50000);
  }, [refreshTokenModal]);
  async function getUserImage(accessToken) {
    var accessDecoded = parseJwt(accessToken);
    const fetchData = {
      method: 'GET',
      url: `${process.env.REACT_APP_ACCESS_V1_URL}/users/${accessDecoded.userUuid ? accessDecoded.userUuid : ''}/profile-picture/download`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-request-id': uuidv4()
      },
    };
    try {
      const response = await axios(fetchData);
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  async function refreshToken() {
    let token = localStorage.getItem("refresh-token");
    setLoading(true);
    const data = {
      'refresh_token': token,
      'client_id': 'hub-logistico',
      'client_secret': process.env.REACT_APP_CLIENT_SECRET,
      'grant_type': 'refresh_token',
      'scope': 'openid'
    };
    const fetchData = {
      method: "POST",
      url: `${process.env.REACT_APP_LOGIN_URL}`,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'x-request-id': uuidv4()
      },
      data: qs.stringify(data),
    }
    try {
      const response = await axios(fetchData);
      return response;
    } catch (error) {
      console.log(error)
      navigate('/');
      return error;
    }
  };
  async function getPermissions(accessToken) {
    const fetchData = {
      method: 'GET',
      url: `${process.env.REACT_APP_ACCESS_V1_URL}/users/permissions`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
        'x-request-id': uuidv4()
      },
    };
    try {
      const response = await axios(fetchData);
      let lastHub = JSON.parse(localStorage.getItem('backoffice-hub'));
      if (lastHub) {
        response.data && response.data.length !== 0 && response.data.map(item => {
          if (item.hub.uuid === lastHub.uuid) {
            item.hub && setHub(item.hub);
            item.type && setAccountType(item.type);
            item.seller && setSeller(item.seller);
            item.profile && setProfile(item.profile);
            setUserData(response.data);
            return setelement(true);
          }
        });
      } else {
        if (response.data && response.data.length !== 0) {
          response.data[0] &&
            response.data[0].hub &&
            setHub(response.data[0].hub);
          response.data[0] &&
            response.data[0].type &&
            setAccountType(response.data[0].type);
          response.data[0] &&
            response.data[0].seller &&
            setSeller(response.data[0].seller);
          response.data[0] &&
            response.data[0].profile &&
            setProfile(response.data[0].profile);
          response.data[0] && setUserData(response.data);
          response.data[0] && setelement(true);
        }
      };
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }
  async function getSellers(accessToken, hub = null, encondedHub = null) {
    setSellersList(null);
    if (hub) {
      const fetchData = {
        method: 'GET',
        url: `${process.env.REACT_APP_ACCESS_V1_URL}/sellers?showVirtualStores=${true}&hub=${hub.uuid}&limit=100000&status=ACTIVE`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          hub: encondedHub,
          'x-request-id': uuidv4()
        },
      };
      try {
        const response = await axios(fetchData);
        setSellersList(response.data.data);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  }
  async function getSellersNoChild(accessToken, hub = null, encondedHub = null) {
    setSellersListNoChild(null);
    if (hub) {
      const fetchData = {
        method: 'GET',
        url: `${process.env.REACT_APP_ACCESS_V1_URL}/sellers?showVirtualStores=${false}&hub=${hub.uuid}&limit=100000&status=ACTIVE`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
          hub: encondedHub,
          'x-request-id': uuidv4()
        },
      };
      try {
        const response = await axios(fetchData);
        setSellersListNoChild(response.data.data);
        return response;
      } catch (error) {
        console.log(error);
        return error;
      }
    }
  }
  function parseJwt(token) {
    try {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      localStorage.removeItem('access-token');
      localStorage.removeItem('id-token');
      localStorage.setItem('sso-error', true);
    }
  }

  function toggleSidebar() {
    if (isSidebarActive) {
      setIsSidebarActive(false);
      $('#sidebar-container').removeClass('show-sidebar');
      $('#sidebar-container').addClass('hide-sidebar');
      $('.sidebar-controller').removeClass('left');
      $('.sidebar-controller').addClass('right');
      changePageSize(' without-sidebar');
    } else {
      $('#sidebar-container').removeClass('hide-sidebar');
      $('#sidebar-container').addClass('show-sidebar');
      $('.sidebar-controller').removeClass('right');
      $('.sidebar-controller').addClass('left');
      changePageSize(' with-sidebar');
      setTimeout(() => {
        setIsSidebarActive(true);
      }, 250);
    }
  }

  function changePageSize(value) {
    setPageSize(value);
  }

  function formatIdToBase64(id, role) {
    var newId = `box-${id}-${role}`;
    var base64Id = new Buffer.from(newId).toString('base64');
    return base64Id;
  }
  return (
    <div className='App'>
      <React.Suspense fallback={<div className='fallback-container'> </div>}>
        {window.location.href.includes('develop') &&
          <div className='dev-warn'>
            <p>Você está em ambiente de development.</p>
          </div>
        }
        <Routes location={location} key={location.pathname}>
          <Route
            path='/'
            exact={true}
            element={
              <Login
                {...props}
                notify={(value) => notify(value)}
                successNotify={(value) => successNotify(value)}
                customNotify={(value) => customNotify(value)}
                formatIdToBase64={(value, role) =>
                  formatIdToBase64(value, role)
                }
                loggedIn={loggedIn}
                setLoggedIn={(value) => setLoggedIn(value)}
                setAccessToken={(value) => setAccessToken(value)}
                setIdToken={(value) => setIdToken(value)}
              />
            }
          />
          <Route
            path='/form/:token'
            exact={true}
            element={
              <SignupForm
                {...props}
                notify={(value) => notify(value)}
                successNotify={(value) => successNotify(value)}
                customNotify={(value) => customNotify(value)}
                formatIdToBase64={(value, role) =>
                  formatIdToBase64(value, role)
                }
              />
            }
          />
          <Route
            path='/recover-password/:token'
            exact={true}
            element={
              <RecoverPassword
                {...props}
                notify={(value) => notify(value)}
                successNotify={(value) => successNotify(value)}
                customNotify={(value) => customNotify(value)}
                formatIdToBase64={(value, role) =>
                  formatIdToBase64(value, role)
                }
                navigate={navigate}
              />
            }
          />
          <Route
            path='/sso'
            element={
              <SSOLogin
                {...props}
                notify={(value) => notify(value)}
                successNotify={(value) => successNotify(value)}
                customNotify={(value) => customNotify(value)}
                setSsoAuthenticated={(value) => setSsoAuthenticated(value)}
              />
            }
          />
          {!localStorage.getItem('access-token') && (
            <Route
              path='*'
              element={(props) => {
                navigate('/');
              }}
            />
          )}
        </Routes>
        {accessToken && element && (
          <>
            <Header
              toggleSidebar={toggleSidebar}
              accountName={accountName}
              accountType={accountType}
              customLogo={customLogo}
              profile={profile}
              accessToken={accessToken}
              profilePicture={profilePicture}
              profilePictureLoading={profilePictureLoading}
            />
            <div className={`pages${pageSize}`}>
              <Sidebar
                changePageSize={(value) => changePageSize(value)}
                isActive={isSidebarActive}
                hub={hub}
                seller={seller}
                profile={profile}
                userData={userData}
                accountType={accountType}
                setHub={(value) => setHub(value)}
                setProfile={(value) => setProfile(value)}
                accessToken={accessToken}
                setSeller={(value) => setSeller(value)}
                getSellers={getSellers}
                getSellersNoChild={getSellersNoChild}
                formatIdToBase64={(value, role) =>
                  formatIdToBase64(value, role)
                }
              />
              <MobileSidebar
                accountType={accountType}
                profile={profile}
                userData={userData}
                hub={hub}
                seller={seller}
              />
              <React.Suspense fallback={<div className='fallback-container'> </div>}>
                <Routes location={location} key={location.pathname}>
                  <Route
                    path='/orders'
                    element={
                      <Orders
                        {...props}
                        seller={seller}
                        sellersList={sellersList}
                        profile={profile}
                        userData={userData}
                        notify={(value) => notify(value)}
                        successNotify={(value) => successNotify(value)}
                        customNotify={(value) => customNotify(value)}
                        formatIdToBase64={(value, role) =>
                          formatIdToBase64(value, role)
                        }
                        accountType={accountType}
                        setLoading={value => setLoading(value)}
                        accessToken={accessToken}
                      />
                    }
                  />
                  {accountType === 'HUB' && [
                    <Route
                      path='/orders/goods'
                      element={
                        <CreateGoodsOrder
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          idToken={idToken}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          sellersList={sellersListNoChild}
                          accountType={accountType}
                          setLoading={value => setLoading(value)}
                          navigate={navigate}
                          location={location}
                        />
                      }
                    />,
                    <Route
                      path='/users/'
                      element={
                        <Users
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                        />
                      }
                    />,
                    <Route
                      path='/users/create'
                      element={
                        <CreateUser
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          sellersList={sellersList}
                          idToken={idToken}
                          accountType={accountType}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                          navigate={navigate}
                        />
                      }
                    />,
                    <Route
                      path='/users/edit'
                      element={
                        <EditUser
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          idToken={idToken}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                          navigate={navigate}
                        />
                      }
                    />,
                    <Route
                      path='/users/profile'
                      element={
                        <EditProfile
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          idToken={idToken}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                          loading={loading}
                          profilePicture={profilePicture}
                          profilePictureLoading={profilePictureLoading}
                          setProfilePicture={setProfilePicture}
                        />
                      }
                    />,
                    <Route
                      path='/sellers/'
                      element={
                        <Sellers
                          {...props}
                          hub={hub}
                          seller={seller}
                          sellersList={sellersList}
                          profile={profile}
                          userData={userData}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          accountType={accountType}
                          setLoading={value => setLoading(value)}
                        />
                      }
                    />,
                    <Route
                      path='/sellers/create'
                      element={
                        <CreateSeller
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          sellersList={sellersList}
                          idToken={idToken}
                          accountType={accountType}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                        />
                      }
                    />,
                    <Route
                      path='/sellers/edit'
                      element={
                        <EditSeller
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          idToken={idToken}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                        />
                      }
                    />,
                    <Route
                      path='/transfers'
                      element={
                        <Transfers
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          accountType={accountType}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                        />
                      }
                    />, 
                    <Route
                      path='/orders/importOrders'
                      element={
                        <ImportOrders
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          idToken={idToken}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          sellersList={sellersList}
                          accountType={accountType}
                          setLoading={value => setLoading(value)}
                          navigate={navigate}
                        />
                      }
                    />
                  ]}
                  {accountType === 'EXTERNAL' && [
                    <Route
                      path='/users/profile'
                      element={
                        <EditProfile
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          idToken={idToken}
                          accessToken={accessToken}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                          loading={loading}
                        />
                      }
                    />,
                    <Route
                      path='/transfers'
                      element={
                        <Transfers
                          {...props}
                          hub={hub}
                          seller={seller}
                          profile={profile}
                          userData={userData}
                          setHub={(value) => setHub(value)}
                          notify={(value) => notify(value)}
                          successNotify={(value) => successNotify(value)}
                          customNotify={(value) => customNotify(value)}
                          formatIdToBase64={(value, role) =>
                            formatIdToBase64(value, role)
                          }
                          setLoading={value => setLoading(value)}
                        />
                      }
                    />
                  ]}
                  <Route
                    path='*'
                    element={(props) => {
                      navigate('/orders');
                    }}
                  />
                </Routes>
              </React.Suspense>
              <ToastContainer />
            </div>
          </>
        )}
      </React.Suspense>
      {loading && (
        <div className='fallback-container'>
          <div className='loading-container'>
            <div className='loading-icon'></div>
          </div>
        </div>
      )}
      <div className='modal' id="refresh-token-modal" style={{ display: 'none' }}>
        <div className='modal-adjust'>
          <div className='modal-content'>
            <div className='modal-header'>
              <p>
                Sua sessão expirou!
              </p>
            </div>
            <div className='modal-body modal-overflow'>
              Deseja continuar logado?
            </div>
            <div className='modal-footer'>
              <Button
                onClick={() => {
                  localStorage.removeItem("access-token");
                  localStorage.removeItem("id-token");
                  localStorage.removeItem("conection-time-out");
                  localStorage.removeItem("profile-picture");
                  localStorage.removeItem("refresh-token");
                  $(`#refresh-token-modal`).toggle();
                  setRefreshTokenModal(false);
                  navigate('/');
                }}
                text='Sair'
                width='180px'
                color='gray'
              />
              <Button
                text='Renovar token'
                width='180px'
                onClick={() => {
                  refreshToken().then(data => {
                    if (data && data.data) {
                      var accessDecoded = parseJwt(data.data.refresh_token);
                      localStorage.setItem("access-token", data.data.access_token);
                      localStorage.setItem("id-token", data.data.id_token);
                      localStorage.setItem("refresh-token", data.data.refresh_token);
                      localStorage.setItem("conection-time-out", parseInt(accessDecoded.exp) * 1000 - 60000);
                      $(`#refresh-token-modal`).toggle();
                      setLoading(false);
                      setRefreshTokenModal(false);
                    } else {
                      localStorage.removeItem("access-token");
                      localStorage.removeItem("id-token");
                      localStorage.removeItem("conection-time-out");
                      localStorage.removeItem("profile-picture");
                      localStorage.removeItem("refresh-token");
                      $(`#refresh-token-modal`).toggle();
                      setRefreshTokenModal(false);
                      navigate('/');
                    }
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default App;
