import React, { useState } from 'react';

const CustomSelect = (props) => {
    const [selected, setSelected] = useState(props.placeholder);
    const onChange = (event) => {
        let selectedId = document.getElementById(props.id).value;
        setSelected(selectedId);
        if(props.onChange) {
            props.onChange(event);
        };
    };
    return (
        <div className={`custom-select ${props.className}`} style={{ width: props.width }}>
            <select
                className={`select${props.filter ? " filter" : " option"}`}
                style={{ color: "#000" }}
                onChange={(event) => onChange(event)}
                id={props.id}
                defaultValue={props.placeholder}
            >
                <option className="options" value={props.placeholder} key={props.placeholder} disabled hidden>{props.placeholder}</option>
                {props.options.map((option, index) => {
                    return (
                        <option value={option} key={option} className="options">{option}</option>
                    )
                })}
            </select>
            {props.required &&
                <div className="required-icon svg-icon-required"></div>
            }
        </div>
    )
}

export default React.memo(CustomSelect);