import React, { createContext, useState } from "react";

const AmbientContext = createContext({});

export const AmbientProvider = (props) => {

    const [hub, setHub] = useState(null)

    const [gmt, setGmt] = useState('GMT-3');

    return (
        <AmbientContext.Provider value={{
            setHub, hub, setGmt, gmt
        }}>
            {props.children}
        </AmbientContext.Provider>
    )
}

export const useAmbient = () => React.useContext(AmbientContext);
