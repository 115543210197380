import React, { Component } from 'react';
import Select from 'react-select';

export const colourOptions = [
    { label: 'Selecione a loja', value: 'select-store', color: '#00B8D9', isDisabled: true },
    { label: 'Renner', value: 'Renner', color: '#00B8D9' },
    { label: 'Riachuelo', value: 'Riachuelo', color: '#0052CC' },
    { label: 'Loja x', value: 'Loja x', color: '#5243AA' }
];

export default class TextSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isClearable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,
            isSearchable: true,
        };
    };
    toggleClearable = () =>
        this.setState((state) => ({ isClearable: !state.isClearable }));
    toggleDisabled = () =>
        this.setState((state) => ({ isDisabled: !state.isDisabled }));
    toggleLoading = () =>
        this.setState((state) => ({ isLoading: !state.isLoading }));
    toggleRtl = () => this.setState((state) => ({ isRtl: !state.isRtl }));
    toggleSearchable = () =>
        this.setState((state) => ({ isSearchable: !state.isSearchable }));
    render() {
        const { isClearable, isSearchable, isDisabled, isLoading, isRtl } = this.state;
        const selectOptions = this.props.sellersList && this.props.sellersList.map(option => {
            var options = {
                label: option.name ? option.name : '',
                value: option.uuid ? option.uuid : '',
                type: option.type ? option.type : '',
            }
            return options;
        });
        const filteredOption = this.props.goods && selectOptions ? selectOptions.filter(option => {
            return option.type === "GOODS"
        })
            :
            this.props.foods && selectOptions ? selectOptions.filter(option => {
                return option.type === "FOODS"
            })
                :
                null;
        filteredOption && filteredOption.unshift({ label: 'Selecione a loja', value: 'select-store', color: '#00B8D9', isDisabled: true });
        return (
            <Select
                className="text-select"
                classNamePrefix="select"
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                options={filteredOption}
                onChange={event => this.props.changeStore(event)}
                value={this.props.store ? this.props.store : filteredOption[0]}
                placeholder={'Selecione a Loja'}
                id={this.props.id}
            />
        );
    }
}