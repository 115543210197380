import Button from '../components/Button';
import CustomSelect from '../components/CustomSelect';
import TextInput from '../components/TextInput';
import IdentifierInput from '../components/IdentifierInput';
import TextSelect from '../components/TextSelect';
import ColoredText from '../components/ColoredText';
import { Component } from 'react';
import axios from 'axios';
import CepInput from '../components/CepInput';
import FileUploader from '../components/FileUploader';
import { validateCpf, validateCnpj, validateEmail, inputErrorAnimation } from '../utils/validations/validations';
import { v4 as uuidv4 } from 'uuid';
import $ from 'jquery';
import Checkbox from '../components/Checkbox';
import { toHaveFocus } from '@testing-library/jest-dom/dist/matchers';

export const colourOptions = [
  { value: 'Status 1', label: 'Status 1', color: '#00B8D9', isFixed: true },
  { value: 'Status 2', label: 'Status 2', color: '#0052CC' },
  { value: 'Status 3', label: 'Status 3', color: '#5243AA', isDisabled: true },
  { value: 'Status 4', label: 'Status 4', color: '#FF5630', isFixed: true },
  { value: 'Status 5', label: 'Status 5', color: '#FF8B00' },
  { value: 'Status 6', label: 'Status 6', color: '#FFC400' },
];

class CreateGoodsOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoice: '',
      deliveryType: '',
      observations: '',
      clientName: '',
      clientEmail: '',
      clientPhone: '',
      clientReceive: '',
      clientAddress: '',
      clientAddressCep: '',
      clientAddressNumber: '',
      clientComplement: '',
      clientAddressNeighborhood: '',
      clientAddressCity: '',
      clientAddressState: '',
      itemDescription: '',
      itemQuantity: 1,
      itemEan: '',
      itemWeight: '',
      itemHeight: '',
      itemWidth: '',
      itemDepth: '',
      itemPrice: '',
      orderIdentifier: '',
      orderTotal: '',
      deliveryVehicle: '',
      deliveryReturn: '',
      paymentThing: '',
      goodsItems: [
        {
          Id: 'goods-1',
          store: '',
          description: '',
          qntd: 1,
          ean: '',
          mask: '9',
          value: '',
          height: '',
          width: '',
          depth: '',
          weight: '',
          seller: '',
          hub: ''
        },
      ],
      store: '',
      render: false,
      invoiceFile: '',
      isSchedule: false,
      reversalLogisticItem: null
    };
  }
  componentDidMount() {
    var accessToken = localStorage.getItem('access-token');
    if (!accessToken || this.props.accountType === 'EXTERNAL') {
      window.location.href = '/';
    }
    if (this.props.location && this.props.location.state && this.props.location.state.reversalLogisticItem) {
      var reversalLogisticItem = this.props.location.state.reversalLogisticItem;
      this.setState({ reversalLogisticItem });
      var localDeliveryType = 'Logística Reversa';
      document.getElementById('goods-delivery-type').value = 'Logística Reversa';
      // if (reversalLogisticItem.shipping.deliveryType === "DELIVERY_REVERSE") {
      //   localDeliveryType = 'Logística Reversa';
      //   document.getElementById('goods-delivery-type').value = 'Logística Reversa';
      // } else if (reversalLogisticItem.shipping.deliveryType === "DRIVE_THRU" && (this.props.hub.name === 'Parque D Pedro Shopping' || this.props.hub.name === 'Parque Dom Pedro Shopping')) {
      //   this.setState({ deliveryType: 'Drive-Thru' });
      //   localDeliveryType = 'Drive-Thru';
      //   document.getElementById('goods-delivery-type').value = 'Drive-Thru';
      // } else if (reversalLogisticItem.shipping.deliveryType === "DRIVE_THRU" && (this.props.hub.name !== 'Parque D Pedro Shopping' && this.props.hub.name !== 'Parque Dom Pedro Shopping')) {
      //   this.setState({ deliveryType: 'Peg no Shopping' });
      //   localDeliveryType = 'Peg no Shopping';
      //   document.getElementById('goods-delivery-type').value = 'Peg no Shopping';
      // } else if (reversalLogisticItem.shipping.deliveryType === "TAKE_AWAY") {
      //   this.setState({ deliveryType: 'Balcão' });
      //   localDeliveryType = 'Balcão';
      //   document.getElementById('goods-delivery-type').value = 'Balcão';
      // }
      document.getElementById('goods-delivery-type').disabled = true;
      let reversalStore;
      if (reversalLogisticItem.items[0] && reversalLogisticItem.items[0].seller && reversalLogisticItem.items[0].seller.name) {
        reversalStore = this.props.sellersList && this.props.sellersList.find(item => item.name === reversalLogisticItem.items[0].seller.name);
      }
      let reversalItems = [];
      if (reversalLogisticItem.items && reversalLogisticItem.items.length > 0) {
        if (reversalLogisticItem.items.length === 1) {
          reversalItems = [
            {
              Id: 'goods-1',
              store: reversalLogisticItem.items[0].seller && reversalLogisticItem.items[0].seller.name && {
                label: reversalLogisticItem.items[0].seller.name,
                value: reversalLogisticItem.items[0].seller.id,
                type: reversalLogisticItem.items[0].seller.type,
              },
              description: reversalLogisticItem.items[0].description && reversalLogisticItem.items[0].description,
              qntd: reversalLogisticItem.items[0].quantity && reversalLogisticItem.items[0].quantity,
              ean: reversalLogisticItem.items[0].ean && reversalLogisticItem.items[0].ean,
              mask: '9',
              value: reversalLogisticItem.items[0].price && (Number(reversalLogisticItem.items[0].price.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
              height: reversalLogisticItem.items[0].dimensions && reversalLogisticItem.items[0].dimensions.height && reversalLogisticItem.items[0].dimensions.height.toString(),
              width: reversalLogisticItem.items[0].dimensions && reversalLogisticItem.items[0].dimensions.width && reversalLogisticItem.items[0].dimensions.width.toString(),
              depth: reversalLogisticItem.items[0].dimensions && reversalLogisticItem.items[0].dimensions.length && reversalLogisticItem.items[0].dimensions.length.toString(),
              weight: reversalLogisticItem.items[0].weight && reversalLogisticItem.items[0].weight.value && reversalLogisticItem.items[0].weight.value.toString(),
              seller: reversalLogisticItem.items[0].seller && reversalLogisticItem.items[0].seller.id && reversalLogisticItem.items[0].seller.id,
              hub: {
                name: reversalLogisticItem.items[0].hub && reversalLogisticItem.items[0].hub.name && reversalLogisticItem.items[0].hub.name,
                id: reversalLogisticItem.items[0].hub && reversalLogisticItem.items[0].hub.id && reversalLogisticItem.items[0].hub.id
              }
            },
          ];
          document.getElementById(`item-description-goods-1`).value = reversalLogisticItem.items[0].description && reversalLogisticItem.items[0].description;
          this.returnCubage(reversalItems[0]);
          if (reversalLogisticItem.items[0] && reversalLogisticItem.items[0].itemsGrouped) {
            reversalLogisticItem.items[0].itemsGrouped.map((item, index) => {
              reversalItems.push({
                Id: `goods-${index + 2}`,
                store: item.seller && item.seller.name && item.seller.name && {
                  label: item.seller.name,
                  value: item.seller.id,
                  type: item.seller.type,
                },
                description: item.description && item.description,
                qntd: item.quantity && item.quantity,
                ean: item.ean && item.ean,
                mask: '9',
                value: item.price && (Number(item.price.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                height: item.dimensions && item.dimensions.height && item.dimensions.height.toString(),
                width: item.dimensions && item.dimensions.width && item.dimensions.width.toString(),
                depth: item.dimensions && item.dimensions.length && item.dimensions.width.toString(),
                weight: item.weight && item.weight.value && item.weight.value.toString(),
                seller: item.seller && item.seller.id && item.seller.id,
                hub: {
                  name: item.hub && item.hub.name && item.hub.name,
                  id: item.hub && item.hub.id && item.hub.id
                }
              });
              return setTimeout(() => {
                document.getElementById(`item-description-goods-${index + 2}`).value = item.description && item.description;
                this.returnCubage(reversalItems[index + 1]);
              }, 100);
            })
          }
        } else {
          reversalLogisticItem.items.map((item, index) => {
            reversalItems.push(
              {
                Id: `goods-${reversalItems.length + 1}`,
                store: item.seller && item.seller.name && item.seller.name && {
                  label: item.seller.name,
                  value: item.seller.id,
                  type: item.seller.type,
                },
                description: item.description && item.description,
                qntd: item.quantity && item.quantity,
                ean: item.ean && item.ean,
                mask: '9',
                value: item.price && (Number(item.price.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                height: item.dimensions && item.dimensions.height && item.dimensions.height.toString(),
                width: item.dimensions && item.dimensions.width && item.dimensions.width.toString(),
                depth: item.dimensions && item.dimensions.length && item.dimensions.length.toString(),
                weight: item.weight && item.weight.value && item.weight.value.toString(),
                seller: item.seller && item.seller.id && item.seller.id,
                hub: {
                  name: item.hub && item.hub.name && item.hub.name,
                  id: item.hub && item.hub.id && item.hub.id
                }
              },
            );
            setTimeout(() => {
              document.getElementById(`item-description-goods-${reversalItems.length + 1}`).value = item.description && item.description;
              this.returnCubage(reversalItems[index]);
            }, 200);
            if (item.itemsGrouped) {
              item.itemsGrouped.map((item, index) => {
                reversalItems.push({
                  Id: `goods-${reversalItems.length + 1}`,
                  store: item.seller && item.seller.name && item.seller.name && {
                    label: item.seller.name,
                    value: item.seller.id,
                    type: item.seller.type,
                  },
                  description: item.description && item.description,
                  qntd: item.quantity && item.quantity,
                  ean: item.ean && item.ean,
                  mask: '9',
                  value: item.price && (Number(item.price.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                  height: item.dimensions && item.dimensions.height && item.dimensions.height.toString(),
                  width: item.dimensions && item.dimensions.width && item.dimensions.width.toString(),
                  depth: item.dimensions && item.dimensions.length && item.dimensions.width.toString(),
                  weight: item.weight && item.weight.value && item.weight.value.toString(),
                  seller: item.seller && item.seller.id && item.seller.id,
                  hub: {
                    name: item.hub && item.hub.name && item.hub.name,
                    id: item.hub && item.hub.id && item.hub.id
                  }
                });
                return setTimeout(() => {
                  document.getElementById(`item-description-goods-${reversalItems.length + 1}`).value = item.description && item.description;
                  this.returnCubage(reversalItems[reversalItems.length + 1]);
                }, 200);
              })
            }
          })
        }
        this.setState({
          invoice: reversalLogisticItem.items[0] && reversalLogisticItem.items[0].invoice ? reversalLogisticItem.items[0].invoice : '',
          deliveryType: localDeliveryType,
          store: reversalStore && reversalStore.name && reversalStore.uuid && reversalStore.type ? {
            label: reversalStore.name ? reversalStore.name : '',
            value: reversalStore.uuid ? reversalStore.uuid : '',
            type: reversalStore.type ? reversalStore.type : '',
          } : '',
          clientName: reversalLogisticItem.customer && reversalLogisticItem.customer.name && reversalLogisticItem.customer.name,
          clientEmail: reversalLogisticItem.customer && reversalLogisticItem.customer.email && reversalLogisticItem.customer.email,
          clientPhone: reversalLogisticItem.customer && reversalLogisticItem.customer.phone && reversalLogisticItem.customer.phone,
          clientAddressCep: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.zipCode ? reversalLogisticItem.shipping.address.zipCode : '',
          clientReceive: reversalLogisticItem && reversalLogisticItem.customer && reversalLogisticItem.customer.receiver ? reversalLogisticItem.customer.receiver : '',
          clientAddress: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.street ? reversalLogisticItem.shipping.address.street : '',
          clientAddressNumber: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.number ? reversalLogisticItem.shipping.address.number : '',
          clientComplement: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.complement ? reversalLogisticItem.shipping.address.complement : '',
          clientAddressNeighborhood: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.neighborhood ? reversalLogisticItem.shipping.address.neighborhood : '',
          clientAddressCity: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.city ? reversalLogisticItem.shipping.address.city : '',
          clientAddressState: reversalLogisticItem.shipping && reversalLogisticItem.shipping.address && reversalLogisticItem.shipping.address.state ? reversalLogisticItem.shipping.address.state : '',
          goodsItems: reversalItems,
          orderTotal: (Number(reversalLogisticItem.price.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
        });
      }
      this.setState({
        render: true,
      });
    }
  }
  addGoodsItem = () => {
    let content = this.state.goodsItems;
    content.push({
      Id: `goods-${this.state.goodsItems.length + 1}`,
      store: '',
      description: '',
      qntd: 1,
      ean: '',
      mask: '9',
      value: '',
      height: '',
      width: '',
      depth: '',
      weight: '',
      seller: '',
      hub: ''
    });
    this.setState({ goodsItems: content });
  };
  removeGoodsItem = (id) => {
    let removedItem = this.state.goodsItems.filter((item) => {
      if (item.Id === id) {
        return item
      }
    });
    let content = this.state.goodsItems.filter((item) => {
      return item.Id !== id;
    });
    content.map(item => {
      let id = parseInt(item.Id.split('-')[1]);
      if (id > parseInt(removedItem[0].Id.split('-')[1])) {
        id = id - 1
        item.Id = `goods-${id}`;
      }
    });
    this.setState({ goodsItems: content });
    if (this.state.render) {
      var values = content.map((item) => {
        return document.getElementById(`item-value-${item.Id}`).value;
      });
      var finalValue = 0;
      values.map((item) => {
        let value = typeof item === 'number' ? item : Number(item.replace(/\D/g, ''));
        return item ? (finalValue += parseFloat(value)) : (finalValue += 0);
      });
      return (document.getElementById(`goods-order-total`).value = finalValue ?
        `${(Number(finalValue.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
        :
        ''
      )
    }
  };
  createGoodsOrder = async () => {
    var identifierValue = '';
    var validCnpj;
    var validCpf;
    if (this.state.deliveryType === 'Delivery' || this.state.deliveryType === 'Logística Reversa') {
      identifierValue = document.getElementById('identifier-input').value;
      validCnpj = validateCnpj(identifierValue);
      validCpf = validateCpf(identifierValue);
    }
    if (this.state.invoice === '' && this.state.deliveryType !== 'Logística Reversa') {
      this.props.notify('Preencha o campo nota fiscal!');
      inputErrorAnimation('goods-invoice');
    } else if (this.state.store === '' && this.props.accountType === 'HUB' && this.state.deliveryType !== 'Logística Reversa') {
      this.props.notify('Preencha o campo loja!');
      inputErrorAnimation('store-select');
    } else if (this.state.deliveryType === '') {
      this.props.notify('Preencha o campo tipo de entrega!');
      inputErrorAnimation('goods-delivery-type');
    } else if (this.state.clientName === '') {
      this.props.notify('Preencha o campo nome do cliente!');
      inputErrorAnimation('goods-client-name');
    } else if (this.state.clientEmail === '') {
      this.props.notify('Preencha o campo e-mail!');
      inputErrorAnimation('goods-client-email');
    } else if (validateEmail(this.state.clientEmail) === false) {
      this.props.notify('O e-mail preenchido não é válido!');
      inputErrorAnimation('goods-client-email');
    } else if (this.state.clientPhone === '') {
      this.props.notify('Preencha o campo telefone do cliente!');
      inputErrorAnimation('goods-client-phone');
    } else if (this.state.clientPhone.length < 18) {
      this.props.notify('O telefone preenchido não é válido!');
      inputErrorAnimation('goods-client-phone');
    } else if (this.state.clientReceive === '') {
      this.props.notify('Preencha o campo quem vai receber o pedido!');
      inputErrorAnimation('goods-client-receive');
    } else if ((this.state.deliveryType === 'Delivery' ||
      this.state.deliveryType === 'Logística Reversa') && identifierValue === '') {
      this.props.notify('Preencha o campo de CPF/CNPJ!');
      inputErrorAnimation('identifier-input');
    } else if (validCpf === false && validCnpj === false) {
      this.props.notify('O CPF/CNPJ é inválido!');
      inputErrorAnimation('identifier-input');
    } else if (
      (this.state.deliveryType === 'Delivery' ||
        this.state.deliveryType === 'Logística Reversa') &&
      this.state.clientAddress === '' &&
      this.state.reversalLogisticItem && this.state.reversalLogisticItem.shipping && this.state.reversalLogisticItem.shipping.deliveryType && this.state.reversalLogisticItem.shipping.deliveryType !== 'TAKE_AWAY'
    ) {
      this.props.notify('Preencha o campo endereço!');
      inputErrorAnimation('goods-client-address');
    } else if (
      (this.state.deliveryType === 'Delivery' ||
        this.state.deliveryType === 'Logística Reversa') &&
      this.state.clientAddressNumber === '' &&
      this.state.reversalLogisticItem && this.state.reversalLogisticItem.shipping && this.state.reversalLogisticItem.shipping.deliveryType && this.state.reversalLogisticItem.shipping.deliveryType !== 'TAKE_AWAY'
    ) {
      this.props.notify('Preencha o campo número!');
      inputErrorAnimation('goods-client-address-number');
    } else {
      var accessToken = localStorage.getItem('access-token');
      var goodsItems = this.state.goodsItems.map((item) => {
        let description = document.getElementById(`item-description-${item.Id}`) && document.getElementById(`item-description-${item.Id}`).value;
        let quantity = document.getElementById(`item-qntd-${item.Id}`) && document.getElementById(`item-qntd-${item.Id}`).value;
        let store = item.store && item.store.label && item.store.label;
        let ean = document.getElementById(`item-ean-${item.Id}`) && document.getElementById(`item-ean-${item.Id}`).value;
        let weight = document.getElementById(`item-weight-${item.Id}`) && document.getElementById(`item-weight-${item.Id}`).value;
        let height = document.getElementById(`item-height-${item.Id}`) && document.getElementById(`item-height-${item.Id}`).value;
        let width = document.getElementById(`item-width-${item.Id}`) && document.getElementById(`item-width-${item.Id}`).value;
        let depth = document.getElementById(`item-depth-${item.Id}`) && document.getElementById(`item-depth-${item.Id}`).value;
        let cubage = document.getElementById(`item-cubage-${item.Id}`) && document.getElementById(`item-cubage-${item.Id}`).value;
        let value = document.getElementById(`item-value-${item.Id}`) && document.getElementById(`item-value-${item.Id}`).value;
        let sellerId = item.store && item.store.value && item.store.value;
        let hubId = document.getElementById(`item-hub-id-${item.Id}`) && document.getElementById(`item-hub-id-${item.Id}`).value;
        let hubName = document.getElementById(`item-hub-name-${item.Id}`) && document.getElementById(`item-hub-name-${item.Id}`).value;
        if (description === '') {
          this.props.notify('Preencha a descrição do item!');
          inputErrorAnimation(`item-description-${item.Id}`);
          return null;
        } else if (quantity === '') {
          this.props.notify('Preencha a quantidade do item!');
          inputErrorAnimation(`item-qntd-${item.Id}`);
          return null;
        } else if (value === '') {
          this.props.notify('Preencha o valor do item!');
          inputErrorAnimation(`item-value-${item.Id}`);
          return null;
        } else if (this.state.deliveryType === 'Logística Reversa' && store === '') {
          this.props.notify('Preencha a loja do item!');
          inputErrorAnimation(`item-store-${item.Id}`);
          return null;
        } else {
          let goodsData = {
            seller: {
              name: store ? store
                : this.props.accountType === 'HUB'
                  ? this.state.store.label
                  : '',
              type: 'GOODS'
            },
            description: description,
            weight: {
              value: parseFloat(weight.replace(',', '.')),
              unit: 'kg',
            },
            dimensions: {
              length: parseFloat(depth),
              width: parseFloat(width),
              height: parseFloat(height),
              unit: 'm',
            },
            cubage: cubage,
            quantity: this.state.deliveryType === 'Logística Reversa' ? 1 : quantity,
            price: value
              .replace('R', '')
              .replace('$', '')
              .replace('.', '')
              .replace('.', '')
              .replace('.', '')
              .replace(',', '')
              .trim(),
            invoice: this.state.invoice
          };
          if (ean !== '') {
            goodsData.ean = ean;
          }
          if (this.state.deliveryType === 'Logística Reversa' && !this.props.location) {
            goodsData.seller.id = sellerId;
          } else if (this.state.deliveryType === 'Logística Reversa' && this.props.location) {
            goodsData.seller.id = sellerId;
            goodsData.hub = {};
            goodsData.hub.id = hubId;
            goodsData.hub.name = hubName;
          }
          return goodsData;
        }
      });
      if (goodsItems[0]) {
        this.props.setLoading(true);
        var encodedHub = this.props.hub.uuid ? this.props.formatIdToBase64(this.props.hub.uuid, 'hub') : null;
        var encodedStore = this.state.store && this.state.store.value ? this.props.formatIdToBase64(this.state.store.value, 'seller') : null;
        var fetchData = {
          method: 'POST',
          url: `${process.env.REACT_APP_ORDER_V1_URL}/orders`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'x-request-id': uuidv4(),
            hub: encodedHub,
          },
          data: {
            orderType: 'GOODS',
            hub: {
              name: this.props.hub.name,
            },
            shipping: this.state.deliveryType === 'Delivery' ?
              {
                deliveryType: 'LAST_MILE',
                address: {
                  zipCode: this.state.clientAddressCep,
                  state: this.state.clientAddressState,
                  city: this.state.clientAddressCity,
                  neighborhood: this.state.clientAddressNeighborhood,
                  street: this.state.clientAddress,
                  number: this.state.clientAddressNumber,
                  complement: this.state.clientComplement,
                },
              }
              : this.state.deliveryType === 'PEG/SAC' ?
                {
                  deliveryType: 'TAKE_AWAY',
                }
                : this.state.deliveryType === 'Drive-Thru' ?
                  {
                    deliveryType: 'DRIVE_THRU',
                  }
                  : this.state.deliveryType === 'Logística Reversa' ?
                    {
                      deliveryType: 'DELIVERY_REVERSE',
                      address: {
                        zipCode: this.state.clientAddressCep,
                        state: this.state.clientAddressState,
                        city: this.state.clientAddressCity,
                        neighborhood: this.state.clientAddressNeighborhood,
                        street: this.state.clientAddress,
                        number: this.state.clientAddressNumber,
                        complement: this.state.clientComplement,
                      },
                    }
                    :
                    {
                      deliveryType: '',
                    },
            customer: {
              name: this.state.clientName,
              email: this.state.clientEmail,
              phone: this.state.clientPhone
                .replace('(', '')
                .replace(')', '')
                .replace('-', ''),
              receiver: this.state.clientReceive,
            },
            items: this.state.observations ? goodsItems.map(item => {
              item.observations = this.state.observations
              return item;
            }) : goodsItems
          },
        };
        if (this.state.invoiceFile) {
          fetchData.data.invoice = this.state.invoiceFile
        }
        if (this.state.deliveryType !== 'Logística Reversa') {
          fetchData.headers.seller = this.props.accountType === 'HUB' ? encodedStore : null;
        }
        if (this.state.deliveryType === "Delivery" || this.state.deliveryType === "Logística Reversa") {
          fetchData.data.customer.document = identifierValue.replaceAll('.', '').replace("-", "");
          if (identifierValue.length > 14) {
            fetchData.data.customer.documentType = 'CNPJ';
          } else {
            fetchData.data.customer.documentType = 'CPF';
          }
        }
        try {
          const response = await axios(fetchData);
          this.props.successNotify('Pedido criado com sucesso!');
          this.props.navigate('/orders');
          this.props.setLoading(false);
        } catch (error) {
          this.props.setLoading(false);
          this.props.notify('Algo deu errado, tente novamente!');
          return error;
        }
      }
    }
  };
  changeStore = (event) => {
    event === null
      ? this.setState({
        store: '',
      })
      : this.setState({
        store: event,
      });
  };
  changeItem = (id, event, name) => {
    let content = this.state.goodsItems;
    if (name === 'qntd') {
      return this.returnTotalValue();
    } else if (name === 'store') {
      content.map((i) => {
        if (i.Id === id) {
          return i[name] = event;
        }
      });
      document.getElementById(`item-${name}-${id}`).value = event.label;
      this.setState({ goodsItems: content });
    } else {
      content.map((i) => {
        if (i.Id === id) {
          return i[name] = event.target.value;
        }
      });
      document.getElementById(`item-${name}-${id}`).value = event.target.value;
      this.setState({ goodsItems: content });
    }
  };
  changeWeight = (event, item) => {
    var string = event.target.value;
    var regex = (Number(string.replace(/\D/g, '')) / 100).toLocaleString(
      'pt-BR',
      { style: 'currency', currency: 'BRL' }
    );
    let content = this.state.goodsItems;
    content.map((i) => {
      if (i.Id === item.Id) {
        return (i.weight = regex.replace('R', '').replace('$', '').trim());
      }
    });
    return this.setState({ goodsItems: content });
  };
  changeCubage = (event, item, dimension) => {
    let content = this.state.goodsItems;
    content.map((i) => {
      if (i.Id === item.Id && dimension === 'height') {
        return (i.height = event.target.value);
      } else if (i.Id === item.Id && dimension === 'width') {
        return (i.width = event.target.value);
      } else if (i.Id === item.Id && dimension === 'depth') {
        return (i.depth = event.target.value);
      }
    });
    this.setState({ goodsItems: content });
    return this.returnCubage(item);
  };
  returnCubage(item) {
    let height = item.height;
    let width = item.width;
    let depth = item.depth;
    let cubage = parseInt(height) + parseInt(width) + parseInt(depth);
    return (document.getElementById(`item-cubage-${item.Id}`).value = `${height ? `${height}cm` : 'Altura'
      }${width ? ` x ${width}cm` : ' x Largura'}${depth ? ` x ${depth}cm` : ' x Profundidade'
      }${cubage ? ` = ${cubage}cm³` : ''}`);
  }
  changeItemValue = (event, item) => {
    var string = event.target.value;
    var regex = (Number(string.replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    let content = this.state.goodsItems;
    content.map((i) => {
      if (i.Id === item.Id) {
        return (i.value = regex);
      }
    });
    this.setState({ goodsItems: content });
    return this.returnTotalValue();
  };
  returnTotalValue = () => {
    var values = this.state.goodsItems.map((item) => {
      return [document.getElementById(`item-value-${item.Id}`).value, document.getElementById(`item-qntd-${item.Id}`).value];
    });
    var finalValue = 0;
    values.map((item) => {
      let value = typeof item[0] === 'number' ? item[0] : Number(item[0].replace(/\D/g, ''));
      item[0] ? (finalValue += parseFloat(value) * item[1]) : (finalValue += 0);
    });
    return (document.getElementById(`goods-order-total`).value = finalValue ? `${(Number(finalValue.toString().replace(/\D/g, '')) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : '');
  };
  onFileChange = (event) => {
    if (event.target.files[0].size && event.target.files[0].size <= 2000000) {
      this.getBase64(event.target.files[0]).then((data) => {
        this.setState({ invoiceFile: data });
      });
    } else {
      this.props.notify('O arquivo deve conter no máximo 2Mb. Tente novamente.');
      this.setState({ invoiceFile: '' });
    }
  };
  getBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    }
  };
  render() {
    const goodsSize = this.state.goodsItems.length;
    return (
      <div className='pages-container'>
        <div className='pages-header new-order'>
          <p className='mr-16'>{this.state.deliveryType === 'Logística Reversa' ? 'Logística Reversa' : 'Novo Pedido'}</p>
          <ColoredText text='Goods' background='#4868FA' />
        </div>
        <div className='new-order-sections'>
          <div className='new-order-section goods' style={this.state.deliveryType === 'Logística Reversa' ? { display: 'none' } : null}>
            <TextInput
              name='goods-invoice'
              placeholder='Nota fiscal'
              value={this.state.invoice}
              onChange={(event) =>
                this.setState({ invoice: event.target.value })
              }
            />
            <FileUploader onFileChange={(event) => this.onFileChange(event)} value={this.state.invoiceFile} />
          </div>
          <div className='new-order-section goods' style={this.state.deliveryType === 'Logística Reversa' ? { display: 'none' } : null}>
            {this.props.sellersList && this.props.sellersList.length > 0 && (
              <>
                <p className='new-order-title'>Loja</p>
                <TextSelect
                  goods
                  sellersList={this.props.sellersList}
                  changeStore={(event) => this.changeStore(event)}
                  store={this.state.store}
                  id="store-select"
                />
              </>
            )}
          </div>
          <div className='new-order-section goods'>
            <p className='new-order-title'>Detalhes da entrega</p>
            <div className='new-order-inputs details'>
              <div>
                <CustomSelect
                  placeholder='Tipo de entrega'
                  className='with-margin'
                  options={[
                    'Drive-Thru',
                    'Logística Reversa',
                    'PEG/SAC'
                  ]}
                  id='goods-delivery-type'
                  width='250px'
                  onChange={(event) => {
                    this.setState({ deliveryType: event.target.value });
                  }}
                />
                <TextInput
                  name='goods-observations'
                  placeholder='Observações'
                  className='no-mr'
                  value={this.state.observations}
                  onChange={(event) =>
                    this.setState({ observations: event.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className='new-order-section goods'>
            <p className='new-order-title'>Dados do cliente</p>
            <div className='new-order-inputs client-data'>
              <div>
                <TextInput
                  name='goods-client-name'
                  placeholder='Nome do cliente'
                  value={this.state.clientName}
                  onChange={(event) =>
                    this.setState({ clientName: event.target.value })
                  }
                />
                <TextInput
                  name='goods-client-email'
                  placeholder='E-mail'
                  value={this.state.clientEmail}
                  onChange={(event) =>
                    this.setState({ clientEmail: event.target.value })
                  }
                />
              </div>
              <div>
                <TextInput
                  name='goods-client-phone'
                  placeholder='Telefone do cliente'
                  value={this.state.clientPhone}
                  onChange={(event) => {
                    this.setState({ clientPhone: event.target.value })
                  }
                  }
                  mask={this.state.clientPhone.length > 18 ? "+55 (99) 99999-9999" : "+55 (99) 9999-99999"}
                />
                {this.state.deliveryType !== '' ? (
                  <TextInput
                    name='goods-client-receive'
                    placeholder={
                      this.state.deliveryType === 'Delivery'
                        ? 'Quem vai receber o pedido?'
                        : this.state.deliveryType === 'Logística Reversa'
                          ? 'Quem vai entregar o pedido?'
                          : 'Quem vai retirar o pedido?'
                    }
                    value={this.state.clientReceive}
                    onChange={(event) =>
                      this.setState({ clientReceive: event.target.value })
                    }
                  />
                ) : (
                  <></>
                )}
              </div>
              <div>
                {(this.state.deliveryType === 'Delivery' || this.state.deliveryType === 'Logística Reversa') &&
                  <IdentifierInput />
                }
              </div>
            </div>
          </div>
          {this.state.deliveryType === 'Delivery' ||
            this.state.deliveryType === 'Logística Reversa' ? (
            <div className='new-order-section address goods'>
              {this.state.deliveryType === 'Delivery' ? (
                <p className='new-order-title'>Local de entrega</p>
              ) : this.state.deliveryType === 'Logística Reversa' ? (
                <p className='new-order-title'>Local de Coleta</p>
              ) : (
                ''
              )}
              {/* <div className="new-order-inputs address">
                                <div>
                                    <TextInput
                                        name="goods-client-address"
                                        placeholder="CEP"
                                        value={this.state.clientCep}
                                        onChange={(event) => this.setState({ clientCep: event.target.value })}
                                        width="25%"
                                    />

                                    <TextInput
                                        name="goods-client-address"
                                        placeholder="Endereço"
                                        value={this.state.clientAddress}
                                        onChange={(event) => this.setState({ clientAddress: event.target.value })}
                                        width="55%"
                                    />
                                    <TextInput
                                        name="goods-client-address-number"
                                        placeholder="Número"
                                        value={this.state.clientAddressNumber}
                                        onChange={(event) => this.setState({ clientAddressNumber: event.target.value })}
                                        width="20%"
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        name="goods-client-address-complement"
                                        placeholder="Complemento"
                                        value={this.state.clientComplement}
                                        onChange={(event) => this.setState({ clientComplement: event.target.value })}
                                        className="no-mb"
                                        width="calc(25% + 5px)"
                                    />
                                    <TextInput
                                        name="goods-client-address-number"
                                        placeholder="Bairro"
                                        value={this.state.clientAddressNeighborhood}
                                        onChange={(event) => this.setState({ clientAddressNeighborhood: event.target.value })}
                                        className="no-mb"
                                        width="25%"
                                    />
                                    <TextInput
                                        name="goods-client-address-complement"
                                        placeholder="Cidade"
                                        value={this.state.clientAddressCity}
                                        onChange={(event) => this.setState({ clientAddressCity: event.target.value })}
                                        className="no-mb"
                                        width="calc(30% - 10px)"
                                    />
                                    <CustomSelect
                                        placeholder="Estado"
                                        className="only-mr"
                                        options={brazilianStates}
                                        id="goods-delivery-type"
                                        width="20%"
                                        onChange={(event) => {
                                            let state = event.target.value.split("-")[1].replace(" ", "");
                                            this.setState({ clientAddressState: state })
                                        }}
                                    />
                                </div>
                            </div> */}
              <div className='new-order-inputs address'>
                <div>
                  <CepInput
                    value={this.state.clientAddressCep}
                    onChange={(event) => this.setState({ clientAddressCep: event.target.value })}
                    notify={this.props.notify}
                    successNotify={this.props.successNotify}
                    setAddress={(value) => this.setState({ clientAddress: value })}
                    setAddressState={(value) => this.setState({ clientAddressState: value })}
                    setAddressNeighborhood={(value) => this.setState({ clientAddressNeighborhood: value })}
                    setAddressCity={(value) => this.setState({ clientAddressCity: value })}
                  />
                </div>
                <div>
                  <TextInput
                    name='goods-client-address'
                    placeholder='Endereço'
                    value={this.state.clientAddress}
                    onChange={(event) =>
                      this.setState({ clientAddress: event.target.value })
                    }
                    disabled={this.state.clientAddressCep && this.state.clientAddressCep.length === 9 ? true : false}
                  />
                  <TextInput
                    name='goods-client-address-neighborhood'
                    placeholder='Bairro'
                    value={this.state.clientAddressNeighborhood}
                    onChange={(event) =>
                      this.setState({ clientAddressNeighborhood: event.target.value })
                    }
                    disabled={this.state.clientAddressCep && this.state.clientAddressCep.length === 9 ? true : false}
                  />
                </div>
                <div>
                  <TextInput
                    name='goods-client-address-city'
                    placeholder='Cidade'
                    value={this.state.clientAddressCity}
                    onChange={(event) =>
                      this.setState({ clientAddressCity: event.target.value })
                    }
                    disabled={this.state.clientAddressCep && this.state.clientAddressCep.length === 9 ? true : false}
                  />
                  <TextInput
                    name='goods-client-address-state'
                    placeholder='Estado'
                    value={this.state.clientAddressState}
                    onChange={(event) =>
                      this.setState({ clientAddressState: event.target.value })
                    }
                    disabled={this.state.clientAddressCep && this.state.clientAddressCep.length === 9 ? true : false}
                  />
                </div>
                <div>
                  <TextInput
                    name='goods-client-address-number'
                    placeholder='Número'
                    value={this.state.clientAddressNumber}
                    onChange={(event) =>
                      this.setState({ clientAddressNumber: event.target.value })
                    }
                    className='no-mb'
                  />
                  <TextInput
                    name='goods-client-address-complement'
                    placeholder='Complemento'
                    value={this.state.clientComplement}
                    onChange={(event) =>
                      this.setState({ clientComplement: event.target.value })
                    }
                    className='no-mb'
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='new-order-section goods'></div>
          )}
          <div className='new-order-section goods'>
            <p className='new-order-title'>Itens do pedido</p>
            <div className='new-order-inputs itens'>
              <div className='goods-items' id='goods-items'>
                {this.state.goodsItems.map((item) => {
                  return (
                    <div className='d-flex column' id={item.Id}>
                      <>
                        {this.state.deliveryType === 'Logística Reversa' ?
                          <div>
                            <TextSelect
                              goods
                              sellersList={this.props.sellersList}
                              changeStore={(value) => this.changeItem(item.Id, value, 'store')}
                              store={item.store}
                              id={`item-store-${item.Id}`}
                            />
                            <TextInput
                              name={`item-description-${item.Id}`}
                              placeholder='Descrição do item'
                              onChange={(value) => this.changeItem(item.Id, value, 'description')}
                              value={item.description}
                              className='no-mr'
                            />
                            <TextInput
                              type='number'
                              name={`item-qntd-${item.Id}`}
                              placeholder='Qntd.'
                              className='no-mr'
                              onChange={(value) => this.changeItem(item.Id, value, 'qntd')}
                              noDisplay
                            />
                          </div>
                          :
                          <div>
                            <TextInput
                              name={`item-description-${item.Id}`}
                              placeholder='Descrição do item'
                              onChange={(value) => this.changeItem(item.Id, value, 'description')}
                              value={item.description}
                            />
                            <TextInput
                              type='number'
                              name={`item-qntd-${item.Id}`}
                              placeholder='Qntd.'
                              className='no-mr'
                              onChange={(value) => this.changeItem(item.Id, value, 'qntd')}
                            />
                          </div>
                        }

                        <div style={this.state.deliveryType === 'Logística Reversa' ? { display: 'none' } : null}>
                          <TextInput
                            name={`item-ean-${item.Id}`}
                            placeholder='EAN'
                            onChange={(value) => this.changeItem(item.Id, value, 'ean')}
                            value={item.ean}
                          />
                          <TextInput
                            name={`item-weight-${item.Id}`}
                            placeholder='Peso (kg)'
                            onChange={(event) =>
                              this.changeWeight(event, item)
                            }
                            value={item.weight}
                          />
                          <TextInput
                            name={`item-height-${item.Id}`}
                            placeholder='Altura (cm)'
                            mask='99999999999'
                            onChange={(event) =>
                              this.changeCubage(event, item, 'height')
                            }
                            value={item.height}
                          />
                          <span className='x-divisor'>x</span>
                          <TextInput
                            name={`item-width-${item.Id}`}
                            placeholder='Largura (cm)'
                            mask='99999999999'
                            onChange={(event) =>
                              this.changeCubage(event, item, 'width')
                            }
                            value={item.width}
                          />
                          <span className='x-divisor'>x</span>
                          <TextInput
                            name={`item-depth-${item.Id}`}
                            placeholder='Profundidade (cm)'
                            mask='99999999999'
                            className='no-mr'
                            onChange={(event) =>
                              this.changeCubage(event, item, 'depth')
                            }
                            value={item.depth}
                          />
                        </div>

                        <div>
                          <TextInput
                            name={`item-cubage-${item.Id}`}
                            placeholder='Cubagem (auto)'
                            disabled
                            noDisplay={this.state.deliveryType !== 'Logística Reversa' ? true : false}
                          />
                          <TextInput
                            name={`item-value-${item.Id}`}
                            placeholder='Valor do item'
                            className='no-mr'
                            onChange={(event) =>
                              this.changeItemValue(event, item)
                            }
                            value={item.value}
                          />
                        </div>
                      </>
                      <div style={{ display: 'none' }}>
                        <TextInput
                          name={`item-seller-id-${item.Id}`}
                          placeholder='ID do seller'
                          value={item.seller}
                        />
                        <TextInput
                          name={`item-hub-id-${item.Id}`}
                          placeholder='ID do hub'
                          value={item.hub.id}
                        />
                        <TextInput
                          name={`item-hub-name-${item.Id}`}
                          placeholder='ID do hub'
                          value={item.hub.name}
                        />
                      </div>
                      {goodsSize > 1 && (
                        <div
                          className='itens-remove goods'
                          onClick={() => this.removeGoodsItem(item.Id)}
                        >
                          X
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className='justify-end mb-20'>
                <Button
                  className='blue'
                  color='blue'
                  onClick={() => this.addGoodsItem()}
                  text='NOVO ITEM'
                  width='130px'
                ></Button>
              </div>
            </div>
          </div>
          <div className='new-order-section goods'>
            <div className='new-order-inputs details'>
              <TextInput
                name='goods-order-total'
                placeholder='Valor total'
                value={this.state.orderTotal}
                disabled
              />
            </div>
          </div>
        </div>
        <div className='new-order-button'>
          <Button
            onClick={() => this.createGoodsOrder()}
            text={'CRIAR PEDIDO'}
            width='130px'
          ></Button>
        </div>
      </div>
    );
  }
}

export default CreateGoodsOrder;
