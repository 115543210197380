import React from 'react';
import { Link } from 'react-router-dom';

const CustomButton = (props) => {
   return (
      <button
         className={`button-container ${props.className ? props.className : ""} ${props.color ? props.color : "yellow"}`}
         style={{ width: props.width, margin: props.margin }}
         onClick={props.onClick}
         id={props.id ? props.id : null}
         disabled={props.disabled ? props.disabled : false}
      >
         {
            props.to ?
               <Link className={`button-inner ${props.color ? props.color : "yellow"}`} to={{ pathname: props.to, state: { reversalLogisticItem: null }, }}>{props.text}</Link>
               : props.image ?
                  <div className={`button-inner ${props.color ? props.color : "yellow"}`} >
                     <input type="file" id="img" name="img" accept="image/jpg, image/jpeg, image/png" onChange={props.onChange} />
                     <p>{props.text}</p>
                  </div>
                  :
                  <span className={`button-inner ${props.color ? props.color : "yellow"}`}>{props.text}</span>
         }
      </button>
   )
}

export default React.memo(CustomButton);