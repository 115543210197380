import React from 'react';
import * as ReactDOM from 'react-dom';
import './assets/styles/css/index.css';
import './assets/styles/css/sprite.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

import ProviderComposer from './providers/index';
import { GoodsProvider } from './providers/goodsProvider';
import { AmbientProvider } from './providers/AmbientProvider';
import { SLAProvider } from './providers/SLAProvider';

const container = document.getElementById('root');

ReactDOM.render(
  <ProviderComposer components={[GoodsProvider, AmbientProvider, SLAProvider]}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ProviderComposer>,
  container
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
