export const formatPrice = (price) => {
    let size = price.toString().length;
    let cents = price.toString().slice(-2);
    let real = price.toString().slice(0, size - 2);
    return `${real}.${cents}`.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
    }).replaceAll('.', ',');
};

function convertTimezone(date) {
    return (typeof date === "string" ? new Date(date) : new Date(date).toLocaleString("pt-BR"));
};

function addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date;
}
export const formatNewDate = (date, gmt, type) => {
    let formatData = new Date(date);
    if (type === 'start') {
        formatData.setHours('00', '00', '00');
    } else if (type === 'end') {
        formatData.setHours('23', '59', '59');
    };
    if (gmt === 'America/Rio_Branco') {
        addHours(5, formatData)
    } else if (gmt === 'America/Manaus') {
        addHours(4, formatData)
    } else if (gmt === 'America/Sao_Paulo') {
        addHours(3, formatData)
    } else if (gmt === 'America/Noronha') {
        addHours(2, formatData)
    }
    return convertTimezone(formatData).replaceAll('/', '-');
};

export const formatDate = (date, gmt) => {
    return new Date(date).toLocaleString("pt-BR", {
        timeZone: `${gmt}`
    })
};

export const formatLogsDate = (date, gmt) => {
    let relativeDate = date.split(' ')[0];
    let relativeHours = date.split(' ')[1];
    let year = relativeDate.split('-')[0];
    let month = relativeDate.split('-')[1];
    let day = relativeDate.split('-')[2];
    let hours = relativeHours.split(':')[0];
    let minutes = relativeHours.split(':')[1];
    let seconds = relativeHours.split(':')[2];
    let UTCDate = Date.UTC(year, parseInt(month) - 1, day, hours, minutes, seconds);
    return UTCDate;
};

export function getBase64(file) {
    if (file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    }
};