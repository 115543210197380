import React, { useEffect } from "react";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const SSO = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("profile-picture");
        const queryString = window.location.search;
        if (queryString !== "") {
            const urlParams = new URLSearchParams(queryString);
            getPermissions(urlParams.get('access_token')).then((data) => {
                if (data.data) {
                    localStorage.setItem("access-token", urlParams.get('access_token'));
                    props.setSsoAuthenticated(true);
                    window.location.href = '/orders';
                } else {
                    props.notify('Erro de login via SSO. Tente novamente ou entre em contato com o administrador.');
                    navigate.push('/');
                }
            });
        } else {
            props.notify('O token SSO não foi reconhecido! Tente novamente.');
            navigate.push('/');
        };
    });
    async function getPermissions(accessToken) {
        const fetchData = {
            method: "GET",
            url: `${process.env.REACT_APP_ACCESS_V1_URL}/users/permissions`,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${accessToken}`,
                'x-request-id': uuidv4()
            },
        };
        try {
            const response = await axios(fetchData);
            return response;
        } catch (error) {
            console.log(error);
            return error;
        }
    }
    return (
        <></>
    )
}

export default React.memo(SSO);