import React, { useState, useEffect, useRef } from "react";
import emptyPhoto from '../assets/images/profile.svg';
import Loading from '../assets/images/loading.svg';
import { Link } from "react-router-dom";

const HeaderDropdown = (props) => {
    const [open, setOpen] = useState(false);

    const container = useRef(null);

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleButtonClick = () => {
        setOpen(!open)
    };
    const handleClickOutside = (event) => {
        if (
            container.current &&
            !container.current.contains(event.target)
        ) {
            setOpen(false)
        }
    };
    const logout = () => {
        localStorage.removeItem("access-token");
        localStorage.removeItem("id-token");
        localStorage.removeItem("conection-time-out");
        localStorage.removeItem("profile-picture");
        localStorage.removeItem("refresh-token");
        window.location.href = "/";
    }
    return (
        <div className="dropdown-container" ref={container}>
        <div className="profile-content" onClick={handleButtonClick}>
            <img className="settings-icon" src={props.profilePictureLoading ? Loading : props.profilePicture ? props.profilePicture : emptyPhoto} alt="user-profile"></img>
            <p className="settings-name">{props.accountName && props.accountName}</p>
            <div className="arrow-icon"></div>
        </div>
        {open && (
            <div className="header-dropdown">
                <ul>
                    {props.accessToken && props.accessToken.userUuid && props.profilePictureLoading === false &&
                        <Link to="/users/profile">Perfil</Link>
                    }
                    <li onClick={() => logout()}>Sair</li>
                </ul>
            </div>
        )}
    </div>
    )
}

export default React.memo(HeaderDropdown);