import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';

const TextInput = (props) => {
    const [inputText, setInputText] = useState('');
    const [inputNumber, setInputNumber] = useState(1);
    const [isPlacheholder, setIsPlacheholder] = useState(true);

    const onChangeInput = (event) => {
        setInputText(event.target.value);
    }

    const showPlaceholder = () => {
        if (isPlacheholder === false) {
            setIsPlacheholder(true);
        } else {
            setIsPlacheholder(false);
        }
    }
    const setLess = () => {
        setInputNumber(inputNumber - 1);
        setTimeout(() => {
            return props.onChange();
        }, 50)
    }
    const setSum = () => {
        setInputNumber(inputNumber + 1);
        setTimeout(() => {
            return props.onChange();
        }, 50)
    }
    return (
        <div className={`input${props.className ? ` ${props.className}` : ''}`} style={{ width: props.width ? props.width : "100%", display: props.noDisplay ? 'none' : 'block' }} id={`${props.name}-container`}>
            {props.mask ?
                <>
                    <InputMask
                        mask={props.mask}
                        maskPlaceholder={null}
                        value={props.value ? props.value : inputText}
                        onChange={props.onChange ? props.onChange : onChangeInput}
                        onFocus={showPlaceholder}
                        onBlur={showPlaceholder}
                        disabled={props.disabled ? true : false}
                        className={`input-inner text${props.value && props.value !== "" ? ' filled' : inputText !== "" ? ' filled' : ''}`}
                        placeholder={isPlacheholder ? props.placeholder : ""}
                        type={props.type ? props.type : "text"}
                        name={props.name}
                        id={props.name}
                        autoComplete="off"
                        required
                        onKeyDown={props.onKeyDown ? props.onKeyDown : null}
                    />
                    <label htmlFor={props.name} className="input-label"><span>{props.placeholder}</span></label>
                    {props.icon &&
                        <div
                            className={`input-icon ${props.icon}`}
                            onClick={() => props.iconClick()}
                        >
                        </div>
                    }
                </>
                : props.type === "number" ?
                    <>
                        <div
                            data-value={props.value ? props.value : inputNumber}
                            disabled={props.disabled ? true : false}
                            className={`input-number`}
                            placeholder={props.placeholder}
                        >
                            Qntd.
                        </div>
                        <div className="input-number-count">
                            <div className="number-icon less" onClick={() => inputNumber === 1 ? null : setLess()}></div>
                            {props.value ? props.value : inputNumber}
                            <input style={{ display: 'none' }} id={props.name} value={props.value ? props.value : inputNumber} onChange={props.onChange ? props.onChange : null} />
                            <div className="number-icon plus" onClick={() => setSum()}></div>
                        </div>
                    </>
                    :
                    <>
                        <input
                            value={props.value ? props.value : inputText}
                            onChange={props.onChange ? props.onChange : onChangeInput}
                            onFocus={showPlaceholder}
                            onBlur={showPlaceholder}
                            disabled={props.disabled ? true : false}
                            className={`input-inner text${props.value && props.value !== "" ? ' filled' : inputText !== "" ? ' filled' : ''}`}
                            placeholder={isPlacheholder ? props.placeholder : ""}
                            type={props.type ? props.type : "text"}
                            name={props.name}
                            id={props.name}
                            autoComplete="off"
                            required
                            onKeyDown={props.onKeyDown ? props.onKeyDown : null}
                        />
                        <label htmlFor={props.name} className="input-label"><span>{props.placeholder}</span></label>
                        {props.icon &&
                            <div
                                className={`input-icon ${props.icon}`}
                                onClick={() => props.iconClick()}
                            >
                            </div>
                        }
                    </>
            }
        </div>
    )
}
export default React.memo(TextInput);