import React, { useState } from 'react';
import InputMask from 'react-input-mask';

const TextInput = (props) => {
    const [inputText, setInputText] = useState('');
    const [mask, setMask] = useState("999.999.999-999");
    const [isPlacheholder, setIsPlacheholder] = useState(true);

    const onChangeInput = (event) => {
        if(event.target.value.length > 14) {
            setMask("99.999.999/9999-99");
        } else {
            setMask("999.999.999-999");
        };
        setInputText(event.target.value);
    }

    const showPlaceholder = () => {
        if (isPlacheholder === false) {
            setIsPlacheholder(true);
        } else {
            setIsPlacheholder(false);
        }
    }

    return (
        <div className={`input${props.className ? ` ${props.className}` : ''}`} style={{ width: props.width ? props.width : "100%" }} id={`${props.name}-container`}>
            <InputMask
                mask={mask}
                maskPlaceholder={null}
                value={inputText}
                onChange={onChangeInput}
                onFocus={showPlaceholder}
                onBlur={showPlaceholder}
                disabled={props.disabled ? true : false}
                className={`input-inner text${inputText !== "" ? ' filled' : ''}`}
                placeholder={isPlacheholder ? "CPF/CNPJ" : ""}
                type="text"
                name='identifier-input'
                id='identifier-input'
                autoComplete="off"
                required
            />
            <label htmlFor='identifier-input' className="input-label"><span>CPF/CNPJ</span></label>
            {props.icon &&
                <div
                    className={`input-icon ${props.icon}`}
                    onClick={() => props.iconClick()}
                >
                </div>
            }
        </div>
    )
}
export default React.memo(TextInput);