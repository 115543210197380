import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import { NavLink } from "react-router-dom";
import { useAmbient } from '../providers/AmbientProvider';

const MobileSidebar = (props) => {
    const [isToggled, setToggle] = useState(false);
    const menubg = useSpring({ background: isToggled ? "rgba(120, 47, 250, .1)" : "transparent" });
    const { y } = useSpring({
        y: isToggled ? 180 : 0
    });
    const menuAppear = useSpring({
        transform: isToggled ? "translate3D(0,0,0)" : "translate3D(0,-40px,0)",
        opacity: isToggled ? 1 : 0
    });

    return (
        <div className="mobile-sidebar">
            <animated.button
                style={menubg}
                className="radiowrapper"
                onClick={() => setToggle(!isToggled)}
            >
                <div className="radio">
                    {/* <animated.p
                        style={{
                            transform: y.interpolate(y => `rotateX(${y}deg)`)
                        }}
                    >
                        ▼
                    </animated.p> */}
                </div>
            </animated.button>
            <animated.div style={menuAppear}>
                {isToggled ? <RadioContent userData={props.userData} accountType={props.accountType} profile={props.profile} /> : null}
            </animated.div>
        </div>
    );
};

const RadioContent = (props) => {
    const [open, setOpen] = useState(false);

    const { hub, setHub } = useAmbient();

    const handleButtonClick = () => {
        setOpen(!open)
    };
    return (
        <div className="radiocontent">
            <div className="hub-filter mobile" onClick={handleButtonClick}>
                {(props.accountType === "HUB" || props.accountType === "EXTERNAL") &&
                    <div className="hub-selected mobile">{hub && hub.name && hub.name}{props.seller && props.seller.name && ` - ${props.seller.name}`}</div>
                }
                {props.accountType === "SELLER" &&
                    <div className="hub-selected mobile">{props.seller && props.seller.name && props.seller.name}{hub && hub.name && ` - ${hub.name}`}</div>
                }
                {props.userData.length > 1 &&
                    <div className="arrow-icon"></div>
                }
            </div>
            {props.userData.length > 1 && open &&
                <div className="hub-dropdown mobile">
                    <ul>
                        {props.accountType === "SELLER" && props.userData && props.userData.map(option => {
                            return (
                                <li key={option.hub.name} onClick={() => {
                                    setHub(option.hub);
                                    setOpen(false);
                                }}>{option.seller && option.seller.name ? option.seller.name : ""}{option.hub && option.hub.name ? ` - ${option.hub.name}` : ""}</li>
                            )
                        })}
                        {(props.accountType === "HUB" || props.accountType === "EXTERNAL") && props.userData && props.userData.map(option => {
                            return (
                                <li key={option.hub.name} onClick={() => {
                                    setHub(option.hub);
                                    setOpen(false);
                                }}>{option.hub && option.hub.name ? option.hub.name : ""}</li>
                            )
                        })}
                    </ul>
                </div>
            }
            <NavLink to="/orders" className="sidebar-link" activeclassname="sidebar-link active">Pedidos</NavLink>
            {(props.accountType === "HUB" || props.accountType === "EXTERNAL") && hub && hub.uuid !== 'a32ac0e2-e259-43b3-b709-5eb1c9740d5a' && hub.name !== 'Parque D Pedro Shopping' && hub.name !== 'Parque Dom Pedro Shopping' &&
                <NavLink to="/transfers" className="sidebar-link" activeclassname="sidebar-link active">Transferências</NavLink>
            }
            {props.accountType === "HUB" && (props.profile === "MASTER" || props.profile === "ADMIN" || props.profile === "SUPERVISOR") &&
                <NavLink to="/users" className="sidebar-link" activeclassname="sidebar-link active">Usuários</NavLink>
            }
            {props.accountType === "HUB" &&
                <NavLink to="/sellers" className="sidebar-link" activeclassname="sidebar-link active">Lojistas</NavLink>
            }
        </div>
    );
};

export default MobileSidebar;