import React from 'react';

const Checkbox = (props) => {
    return (
        <label className={`checkbox ${props.className}`} style={{ width: props.width }}>
        <span className={props.icon ? "ml-88" : ""}>{props.value}</span>
        <input
            type="checkbox"
            id={`checkbox-${props.name}`}
            name={props.name}
            value={props.value}
            checked={props.checked}
            onChange={props.onChange}
        />
        <span className="checkbox-checkmark" htmlFor={props.name}></span>
    </label>
    )
}

export default React.memo(Checkbox);