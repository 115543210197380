import Dropdown from "./HeaderDropdown";
import DropdownSeller from "./HeaderDropdownSeller";

function Header(props) {
    return (
        <nav className="header-container">
            <div>
                <img className='logo' src={props.customLogo} alt="logo" />
                <div className="sidebar-controller" id="sidebar-controller" onClick={() => props.toggleSidebar()}></div>
            </div>
            {props.accountType === "SELLER" && props.profile ?
                <DropdownSeller profilePictureLoading={props.profilePictureLoading} profilePicture={props.profilePicture} accountName={props.accountName && props.accountName} accessToken={props.accessToken} />
                :
                <Dropdown profilePictureLoading={props.profilePictureLoading} profilePicture={props.profilePicture} accountName={props.accountName && props.accountName} accessToken={props.accessToken} />
            }
        </nav>
    );
}

export default Header;
