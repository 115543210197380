import React, { useState } from "react";

const FileUploader = (props) => {
    const [selectedFile, setSelectedFile] = useState("");
    const [fileName, setFileName] = useState("Nenhuma nota fiscal selecionada");

    const onFileChange = event => {
        setSelectedFile(event.target.files[0]);
    };
    const changeFile = event => {
        props.onFileChange(event);
        event.target && event.target.files && event.target.files[0] && event.target.files[0].name && event.target.files[0].size <= 2000000 && setFileName(event.target.files[0].name);
        event.target.files[0].size > 2000000 && setFileName("Nenhuma nota fiscal selecionada");
    }
    return (
        <div className="file-uploader">
        <input
            className="file"
            type="file"
            onChange={event => props.onFileChange ? changeFile(event) : onFileChange(event)}
            accept="application/pdf"
        />
        <span>ANEXAR NOTA FISCAL</span>
        <span className="file-name">{fileName ? fileName : ""}</span>
    </div>
    )
}

export default React.memo(FileUploader);