import React, { useState } from "react";


const GoodsContext = React.createContext( {} )

export const GoodsProvider = ( props ) => {

  const [hasGoods, setHasGoods] = useState( false )

  const [order, setOrder] = useState( {} );

  return (
    <GoodsContext.Provider value={{
      setOrder, order, setHasGoods, hasGoods
    }}>{props.children}</GoodsContext.Provider>
  )
}

export const useGoods = () => React.useContext( GoodsContext )