import React, { createContext, useState } from "react";

const SLAContext = createContext({});

export const SLAProvider = (props) => {

    const [takeAwaySla, setTakeAwaySla] = useState('40');
    const [driveThruSla, setDriveThruSla] = useState('60');
    const [lastMileSla, setLastMileSla] = useState('240');
    const [postOfficeSla, setPostOfficeSla] = useState('90');
    const [expressSla, setExpressSla] = useState('180');

    return (
        <SLAContext.Provider value={{
            takeAwaySla, setTakeAwaySla, driveThruSla, setDriveThruSla, lastMileSla, setLastMileSla, postOfficeSla, setPostOfficeSla, expressSla, setExpressSla
        }}>
            {props.children}
        </SLAContext.Provider>
    )
}

export const useSLA = () => React.useContext(SLAContext);
